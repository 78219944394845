import Image from 'next/future/image';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getOurBrands } from '@/services/home';

const OurBrands = () => {
  const [ourBrandsData, setOurBrandsData] = useState([]);
  const brands = async () => {
    const ourBrandsRes = await getOurBrands();
    const ourBrands = (await ourBrandsRes?.data?.data) || null;
    setOurBrandsData(ourBrands);
  };
  useEffect(() => {
    brands();
  }, []);
  return (
    <div className="container">
      <div className="our-brands">
        <h4>
          <span>
            <FormattedMessage id="our-brands" />
          </span>
        </h4>

        <div className="brands-list">
          <div className="row justify-content-between justify-content-md-between justify-content-sm-center align-items-center gy-4 gx-md-2 gx-sm-3">
            {ourBrandsData &&
              ourBrandsData?.map((item: any) => (
                <div className="col-auto" key={item.id}>
                  <a
                    href={item?.attributes?.Site_URL}
                    className="brand-logo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={item?.attributes?.Logo?.data?.attributes?.url}
                      alt={
                        item?.attributes?.Logo?.data?.attributes
                          ?.alternativeText
                      }
                      width="103"
                      height="35"
                      className="brand-image"
                    />
                  </a>
                </div>
              ))}
          </div>
        </div>

        <div className="brands-list-mobile">
          <div className="logo-list">
            <div className="first-row">
              {ourBrandsData &&
                ourBrandsData?.map(
                  (item: any) =>
                    item.id < 5 && (
                      <a
                        target="_blank"
                        href={item?.attributes?.Site_URL}
                        className="brand-logo"
                        key={item?.id}
                        rel="noreferrer"
                      >
                        <Image
                          className="logo-img"
                          src={item?.attributes?.Logo?.data?.attributes?.url}
                          alt={
                            item?.attributes?.Logo?.data?.attributes
                              ?.alternativeText
                          }
                          width="103"
                          height="35"
                        />
                      </a>
                    )
                )}
            </div>

            <div className="sec-row">
              {ourBrandsData &&
                ourBrandsData?.map(
                  (item: any) =>
                    item.id < 8 &&
                    item.id > 4 && (
                      <a
                        href={item?.attributes?.Site_URL}
                        target="_blank"
                        className="brand-logo"
                        key={
                          item?.attributes?.Logo?.data.attributes
                            .alternativeText
                        }
                        rel="noreferrer"
                      >
                        <Image
                          className="logo-img"
                          src={item?.attributes?.Logo?.data?.attributes?.url}
                          alt={
                            item?.attributes?.Logo?.data?.attributes
                              ?.alternativeText
                          }
                          width="103"
                          height="35"
                        />
                      </a>
                    )
                )}
            </div>

            <div className="third-row justify-content-center">
              {ourBrandsData &&
                ourBrandsData?.map(
                  (item: any) =>
                    item.id < 10 &&
                    item.id > 7 && (
                      <a
                        href={item?.attributes?.Site_URL}
                        target="_blank"
                        className="brand-logo"
                        key={
                          item?.attributes?.Logo?.data.attributes
                            .alternativeText
                        }
                        rel="noreferrer"
                      >
                        <Image
                          className="logo-img"
                          src={item?.attributes?.Logo?.data?.attributes?.url}
                          alt={
                            item?.attributes?.Logo?.data?.attributes
                              ?.alternativeText
                          }
                          width="103"
                          height="35"
                        />
                      </a>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurBrands;
